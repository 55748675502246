import LazyLoad from './modules/LazyLoad';
import LoaderAnimation from './modules/LoaderAnimation';
import {
    VenokoElement,
    VenokoSelectBox,
    VenokoMap,
} from './modules/VenokoMap';

const tS = window.themeSettings;
var options = {

    map: {
        mainLatitude: 63.439339,
        mainLongitude: 10.401701,
        mainZoom: 11
    },

    icons: {
        stockMarker: {
            url: tS.images.google_maps.markers.not_active,
        },

        activeMarker: {
            url: tS.images.google_maps.markers.active,
        }

    },

};



var map = new VenokoMap(options);
var selectBox = new VenokoSelectBox();
var loaderAnimation = new LoaderAnimation();

if (map.googleMapsIsInDOM()) {
    map.initialiseGoogleMaps();
    map.initialiseRequest('/wp-content/themes/trondheim/get-data.php');
    map.request.onload = function() {
        if (map.requestResponseHasPosts()) {
            map.setPosts();
            map.setCategories();
            map.initialiseMarkers();

            map.spreadMarkers();

            map.initialiseClusters();
            map.initialiseMarkersClickListener();
            map.setCategoryButtons('min-bydel');

            selectBox.initialise(
                function(parameter) {
                    map.changeCategories('Min bydel', parameter);
                }
            )

            selectBox.setSelectBoxIcon("<i class='fas fa-chevron-down'></i>", "<i class='fas fa-times'></i>");
            map.isSinglePostPage();
            map.isSubCategoryPage();

            map.initialiseClosePostWindowListener();

            loaderAnimation.endLoading();

        }
    }
}



//Lazy load

var lazyLoadObject = new LazyLoad();
var array = document.querySelectorAll('.lazy-load');
var array2 = Array.from(array);
lazyLoadObject.initialise(100, array2);




//Load more functionality
var loadMode = new VenokoElement();
if (loadMode.findAll('.load-more-parent')) {
    loadMode.loadMore(3, '.load-more-parent', '.load-more-content', '.load-more-button', 'hide');
}

//Add closed class to hamburger menu in map for animation
if (document.querySelector('.filter.close.button')) {
    document.querySelector('.filter.close.button').addEventListener('click', function() {
        document.querySelector('.filtering').classList.toggle('closed');
        document.querySelector('.filter.close.button').classList.toggle('closed');
    });
}


//If facebook, set facebook height based on parent container height
if (document.querySelector('.ams-facebook .fb-page') && screen.width > 640) {
    var facebook = document.querySelector('.ams-facebook .fb-page');
    var facebookParent = document.querySelector('.ams-facebook');
    facebook.dataset.height = facebookParent.offsetHeight;
}






//Universell utforming
//Nyhetsbrev feilmeldinger


var newsletters = document.querySelectorAll('.newsletter-widget');
for (var i = 0; i < newsletters.length; i++) {
    let newsletter = newsletters[i];
    let newsletterButton = newsletter.querySelector('button');
    let inputField = newsletter.querySelector('input[type=email]');
    let checkBox = newsletter.querySelector('input[type=checkbox]');
    let errorMessage = newsletter.querySelector('.ams-error');

    newsletterButton.addEventListener('click', function () {



        /*error messages*/
        if (inputField.value.length == 0) {
            errorMessage.innerText = "Du må skrive inn en e-postadresse.";
        }

        else if (inputField.value.length == 0 && checkBox.checked == false) {
            errorMessage.innerText = "Du må skrive inn en e-postadresse.";
        }

        else if (inputField.value.length > 0 && checkBox.checked == false) {
            errorMessage.innerText = "Du må godta vilkårene.";
        }

        else {
            errorMessage.innerText = "";
        }
    })

}

//focus on search inputfield when inputfield open
var searchButton = document.querySelector('button.header-search');
var searchInput = document.querySelector('input.search-input');
var htmlBody = document.querySelector('body');
searchButton.addEventListener('keypress', function (e) {
    if (e.keyCode == "13" && (!htmlBody.classList.contains('header-search-open'))) {
        searchInput.focus();
    }
})
