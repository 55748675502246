export function VenokoSelectBox() {

    var self = {
        /* Look for the container of the select tag, "custom-dropdown-menu" */
        count: 0,
        customDropdownMenu: document.getElementsByClassName("custom-dropdown-menu"),
        /* Iterate through all the dropdown menus */

        initialise: function(optionItemCallback) {
            for (let i = 0; i < this.customDropdownMenu.length; i++) {
                let selectTagElement = this.customDropdownMenu[i].getElementsByTagName("select")[0];
                /* For each dropdown menu, create a new DIV that will act as the class "select-box" */
                let selectBox = document.createElement("DIV");
                /* For each dropdown menu, create a new DIV that will act as the class "select-box-text" */
                let selectBoxText = document.createElement("DIV");
                /* Add classes to the DIVs */
                selectBox.setAttribute("class", "select-box");
                selectBoxText.setAttribute("class", "select-text");
                /* Set the select-box-text to contain the stock select box text (the stock option) */
                selectBoxText.innerHTML = selectTagElement.options[selectTagElement.selectedIndex].innerHTML;
                /* Put the select-box-text inside the select-box */
                selectBox.appendChild(selectBoxText);
                /* Put the select box inside the dropdown menu */
                this.customDropdownMenu[i].appendChild(selectBox);
                /* For each dropdown menu, create a new DIV that will contain the option-list */
                let optionList = document.createElement("DIV");
                /* Add classes to the DIV. Option-list-hide makes sure the DIV is hidden by default */
                optionList.setAttribute("class", "option-list option-list-hide");
                /* For each option in the original select tag element */
                for (let j = 0; j < selectTagElement.length; j++) {
                    /* Create a new DIV that will act as "option-item" */
                    let optionItem = document.createElement("DIV");
                    /* Create a new DIV that will act as "option-item-text" */
                    let optionItemText = document.createElement("DIV");
                    /* Add the text-styling class "select-text" */
                    optionItemText.setAttribute("class", "select-text");
                    /* Add the option-text to the option-item-text div */
                    optionItemText.innerHTML = selectTagElement.options[j].innerHTML;
                    /* Put the option-item-text inside the option-item div */
                    optionItem.appendChild(optionItemText);
                    /* Add an event listener to the option items, listening for a click. The following code
                    updates the original select-box to contain the newly selected option-item */
                    optionItem.addEventListener("click", function() {
                        var selectedOption, optionItemSelectTag, selectedOptionText;
                        /* Indexing variables */
                        var i, k;
                        /* The select tag belonging to the option-item */
                        optionItemSelectTag = this.parentNode.parentNode.getElementsByTagName("select")[0];
                        /* The text belonging to the the selected option-item */
                        selectedOptionText = this.parentNode.previousSibling.children[0];
                        /* For each option beloning to the select tag */
                        for (i = 0; i < optionItemSelectTag.length; i++) {
                            /* Find the the option that is clicked on */
                            if (optionItemSelectTag.options[i].textContent == this.textContent) {
                                /* Update the index of the select-tag-box, to the index of the option that is clicked on */
                                optionItemSelectTag.selectedIndex = i;
                                /*Update the text for the select-box*/
                                selectedOptionText.textContent = this.textContent;
                                /*Find the option-item that is already selected and currently in the select-box*/
                                selectedOption = this.parentNode.getElementsByClassName("option-already-selected");
                                /*Remove the classes from the selected-option*/
                                for (k = 0; k < selectedOption.length; k++) {
                                    selectedOption[k].classList.remove('option-already-selected');
                                }
                                /*Update this option item to be the new selected option item in the option-list*/
                                this.setAttribute("class", "option-already-selected");

                                break;
                            }
                        }

                        optionItemCallback(selectTagElement.options[selectTagElement.selectedIndex].value);

                    });
                    /* Add the option item to the option-list */
                    optionList.appendChild(optionItem);
                }
                /* Add the option-list to the dropdown menu */
                this.customDropdownMenu[i].appendChild(optionList);

                /* When the select-box is clicked, close any other select-boxes,
                  and open/close the current select-box*/
                selectBox.addEventListener("click", function(e) {
                    /* Stop the event from bubbling up the event chain */
                    e.stopPropagation();
                    /* Close the option-lists except for this select-box */
                    self.closeOptionList(this);
                    /* Open/close the option-list for this select-box */
                    this.nextSibling.classList.toggle("option-list-hide");
                    /* Set this select-box to active/inactive */
                    this.classList.toggle("select-box-active");

                    //selectBoxCallback(this);

                });
            }
        },

        /* A function that will close all select-boxes in the document,
        except the current select-box */
        closeOptionList: function (selectBox) {
            var optionList, selectBoxes, currentSelectBoxIndex = [];
            /*Indexing variables*/
            var i;
            /* Get the option-list for the select-box */
            optionList = document.getElementsByClassName("option-list");
            /* Get all the select-boxes */
            selectBoxes = document.getElementsByClassName("select-box");
            /*Find the current select-box, and set all other select-boxes to inactive*/
            for (i = 0; i < selectBoxes.length; i++) {
                if (selectBox === selectBoxes[i]) {
                    currentSelectBoxIndex.push(i);
                } else {
                    selectBoxes[i].classList.remove("select-box-active");
                }
            }

            /*Iterate through the option-lists*/
            for (i = 0; i < optionList.length; i++) {
                /*Find the index of the current select-box*/
                if (currentSelectBoxIndex.indexOf(i)) {
                    /*Hide the current select-box option-list*/
                    optionList[i].classList.add("option-list-hide");
                }
            }

        },

        /* Creat select-box-icon and puts a img tag inside the DIV. Takes image path as input */
        setSelectBoxIcon: function(elementClosed, elementOpen) {
            var i;
            for (i = 0; i < this.customDropdownMenu.length; i++) {
                var selectBoxIcon;
                var selectBoxes = document.getElementsByClassName("select-box");
                /* For each dropdown menu, create a new DIV that will act as the class "select-box-icon" */
                selectBoxIcon = document.createElement("DIV");
                selectBoxIcon.setAttribute("class", "select-box-icon");

                var elementClosedDiv = document.createElement('DIV');
                elementClosedDiv.setAttribute("class", "closed");
                elementClosedDiv.innerHTML = elementClosed;
                selectBoxIcon.appendChild(elementClosedDiv);

                var elementOpenDiv = document.createElement('DIV');
                elementOpenDiv.setAttribute("class", "open");
                elementOpenDiv.innerHTML = elementOpen;
                selectBoxIcon.appendChild(elementOpenDiv);

                /* Put the select-box-icon inside the select-box */
                selectBoxes[i].appendChild(selectBoxIcon);
            }
        }

    }

    return self;
}

export function VenokoElement() {

    var self = {

        //Element vars
        elementArray: [],
        currentIterationElement: '',
        currentElement: '',

        forEachElement: function(array, callback) {
            for (var i = 0; i < array.length; i++) {
                let element = array[i];
                callback(element);
            }
        },

        find: function(selector) {
            this.elementArray = [];
            if (document.querySelector(selector)) {
                this.elementArray.push(document.querySelector(selector));
                this.currentElement = document.querySelector(selector);
                return true;
            } else {
                return false;
            }


        },

        findAll: function(selector) {
            this.elementArray = [];
            if (document.querySelectorAll(selector)) {
                this.elementArray = document.querySelectorAll(selector);
                return true;
            } else {
                return false;
            }
        },

        addClass: function(cssClass) {
            if (!self.currentIterationElement.classList.contains(cssClass)) {
                self.currentIterationElement.classList.add(cssClass);
            }
        },

        removeClass: function(cssClass, element) {

            if (element.classList.contains(cssClass)) {
                element.classList.remove(cssClass);
            }


        },

        loadMore: function(numberOfElements, parentSelector, contentSelector, buttonSelector, hiddenClass) {

            let parentDivs = document.querySelectorAll(parentSelector);

            //For each parent div
            this.forEachElement(parentDivs, function(parentDiv) {

                //Calculate how many posts are already visible, based on cssClass argument
                let postsLoadedVar = 0;
                //Get content div (child of current parent div)
                let contentDiv = parentDiv.querySelector(contentSelector);

                //Calculate how many content div children are already visible
                for (var i = 0; i < contentDiv.children.length; i++) {
                    if (!(contentDiv.children[i].classList.contains(hiddenClass))) {
                        postsLoadedVar += 1;
                    }
                }

                //Get the button div (child of current parent div)
                let buttonDiv = parentDiv.querySelector(buttonSelector);
                //Add click listener
                buttonDiv.addEventListener('click', function() {
                    //Remove class from content div children (argument hiddenClass)
                    for (var i = postsLoadedVar; i < (postsLoadedVar + numberOfElements); i++) {
                        if (contentDiv.children[i]) {
                            self.removeClass(hiddenClass, contentDiv.children[i]);
                        } else {
                            buttonDiv.classList.add('low-opacity');
                            break;
                        }
                    }
                    postsLoadedVar = (postsLoadedVar + numberOfElements);
                })

            })

        },

        lazyLoad: function() {
            var url = self.currentIterationElement.dataset.imageurl;
            self.currentIterationElement.style.backgroundImage = "url(" + url + ")";
        },

        isInViewport: function(callback) {
            var callback = callback;

            //Run callback once, before scrolllistener is intialiased
            self.forEachElement(this.elementArray, function(element) {
                if ((self.percentageInViewport(element) > 0) && (self.percentageInViewport(element) <= 100)) {
                    self.currentIterationElement = element;
                    callback();
                }
            })

            window.addEventListener('scroll', function() {
                window.requestAnimationFrame(function() {
                    self.forEachElement(self.elementArray, function(element) {
                        if ((self.percentageInViewport(element) > 0) && (self.percentageInViewport(element) <= 100)) {
                            self.currentIterationElement = element;
                            callback();
                        }
                    })
                });
            });
        },

        percentageInViewport: function(element) {
            return 100 - (element.getBoundingClientRect().top / (window.innerHeight || document.documentElement.clientHeight) * 100);
        }

    }
    return self;

}

export function VenokoMap(options) {

    var self = {
        //Properties
        options: options,
        request: undefined,
        map: undefined,
        markers: [],
        markerCluster: undefined,
        singlePostPagePostID: undefined,
        subCategoryPageCategoryID: undefined,
        posts: undefined,
        categories: undefined,
        categoriesOnMap: {},
        categoryMain: 'Alle bydeler',


        //Map marker pop-up window
        postWindowContainer: document.querySelector('.post-container'),
        postWindowContainerButton: document.querySelector('.post-container .button.close'),
        postWindowCategories: document.querySelector('.post-container .post-categories'),
        postWindowTitle: document.querySelector('.post-container .post-title'),
        postWindowImage: document.querySelector('.post-container .post-image'),
        postWindowExcerpt: document.querySelector('.post-container .post-excerpt'),
        postWindowLinks: document.querySelectorAll('.post-container .post-link'),


        //Intialisers
        initialiseGoogleMaps: function() {
            var map = new google.maps.Map(document.getElementById('ams-map'), {
                center: { lat: self.options.map.mainLatitude, lng: self.options.map.mainLongitude },
                zoom: self.options.map.mainZoom,
                disableDefaultUI: false,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false

            });

            this.map = map;
        },


        initialiseRequest: function(requestURL) {
            let request = new XMLHttpRequest();
            request.open('GET', requestURL, true);
            request.responseType = 'json';
            request.send();
            this.request = request;
        },

        initialiseMarkers: function() {

            for (var i = 0; i < this.posts.length; i++) {

                //Split coordinates and format for correct Marker object arguments (and for zoom if current page is article subpage)
                var coordinates = this.posts[i]['meta']['options_location'][0].split(",");
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(coordinates[0], coordinates[1]),
                    postID: this.posts[i]['ID'],
                    map: this.map,
                    title: this.posts[i]['post_title'],
                    icon: this.options.icons.stockMarker,
                    categories: this.posts[i]['categories'],
                    imageURL: this.posts[i]['image_url'],
                    excerpt: this.posts[i]['post_excerpt'],
                    link: this.posts[i]['amsURL'],
                    eventObjectFormatted: this.posts[i]['eventObjectFormatted'],
                    eventObject: this.posts[i]['eventObject'],
                    meetingPoint: this.posts[i]['meetingPoint']
                });

                this.markers.push(marker);

                //Create object with all categories currently in use on map (used for intialising category filtering buttons)
                for (var j = 0; j < marker.categories.length; j++) {
                    if (!this.categoriesOnMap[marker.categories[j]['name']]) {
                        this.categoriesOnMap[marker.categories[j]['name']] = marker.categories[j];
                    }
                }

            }
        },

        spreadMarkers: function() {

            var positions = [];

            for (var i = 0; i < this.markers.length; i++) {

                var position = this.markers[i].position;
                var marker = this.markers[i];

                if (this.isInArray(positions, this.markers[i].position)) {
                    let currentLat = position.lat();
                    let currentLng = position.lng();

                    /*newLat = currentLat - (i%5 / 10000);
                    newLng = currentLng - (i%3 / 20000);*/

                    let newLat = currentLat - (i%5 / 30000);
                    let newLng = currentLng - (i%3 / 20000);

                    let newPosition =  new google.maps.LatLng(newLat , newLng);

                    this.markers[i].position = newPosition;
                }

                positions.push(this.markers[i].position);
            }
        },

        initialiseClusters: function() {
            this.markerCluster = new MarkerClusterer(this.map, this.markers, {
                maxZoom: 18,
                styles: [{
                    url: window.themeSettings.images.google_maps.cluster,
                    height: 74,
                    width: 74,
                    textColor: 'white',
                    textSize: 18
                }]
            });
        },

        //Iterate through markers and add eventlistener
        initialiseMarkersClickListener: function() {
            for (var i = 0; i < this.markers.length; i++) {

                google.maps.event.addListener(this.markers[i], 'click', (function(marker, i) {
                    return function() {
                        //Set all icons to stock icon
                        for (var i = 0; i < self.markers.length; i++) {
                            self.markers[i].setIcon(self.options.icons.stockMarker);
                        }
                        //Set clicked icon to active marker
                        marker.setIcon(self.options.icons.activeMarker);

                        //Remove current categories from postWindow before adding new ones
                        while (self.postWindowCategories.firstChild) {
                            self.postWindowCategories.removeChild(self.postWindowCategories.firstChild);
                        }

                        //Iteraite through marker categories and add to postWindow
                        for (var j = 0; j < marker.categories.length; j++) {
                            var postWindowCategory = document.createElement("H4");
                            //Get category parent ID
                            var categoryParentID = marker.categories[j]['parent'];
                            //Get and use categoryparent for slug (color/css class)
                            var categoryParent = self.getObjectInsideArray(self.categories, 'term_id', categoryParentID);

                            if (categoryParent) {
                                var categoryMainlug = categoryParent['slug'];
                                postWindowCategory.classList.add('post-category', categoryMainlug);
                            } else {
                                postWindowCategory.classList.add('post-category', marker.categories[j]['slug']);
                            }

                            //Create category link and append to h4-tag
                            var postWindowCategoryLink = document.createElement("A");
                            postWindowCategoryLink.href = marker.categories[j]['amsURL'];
                            postWindowCategoryLink.innerText = marker.categories[j]['name'];
                            postWindowCategory.appendChild(postWindowCategoryLink);

                            //Add category to DOM
                            self.postWindowCategories.appendChild(postWindowCategory);

                            //Add seperator between categories, if current cateogry is not the last category
                            if (j !== (marker.categories.length - 1)) {
                                var seperator = document.createElement("SPAN");
                                seperator.classList.add('seperator');
                                seperator.innerText = ', ';
                                self.postWindowCategories.appendChild(seperator);
                            }

                        }

                        //Sett post window values
                        self.postWindowTitle.innerText = marker.title;
                        self.postWindowImage.style.backgroundImage = "url('" + marker.imageURL + "')";
                        self.postWindowExcerpt.innerText = marker.excerpt;

                        //Add post links to the links in postwindow
                        for (var i = 0; i < self.postWindowLinks.length; i++) {
                            self.postWindowLinks[i].href = marker.link;
                        }

                        //Logic for removing 'closed' if the window is already closed
                        if (self.postWindowContainer.classList.contains('closed')) {
                            self.postWindowContainer.classList.remove('closed');
                        }

                        //Arrangement divs
                        var arrangementDescription = document.querySelector('.description.arrangement');
                        var overlay = document.querySelector('.map-wrapper .overlay');
                        var overlayText = document.querySelector('.map-wrapper .overlay-text');
                        //Show arrangement info if arrangement
                        if (marker['eventObject'] !== false) {
                            arrangementDescription.classList.remove('hide');
                            self.setArrangementInfo(marker['eventObject'], marker['eventObjectFormatted']);
                        } else {
                            arrangementDescription.classList.add('hide');
                            overlay.classList.add('hide');
                            overlayText.classList.add('hide');
                        }

                        //Meetingpoint divs
                        var meetingPointDescription = document.querySelector('.description.meeting-point');
                        //Show meetingpoint info if meetingpoint



                        if (marker['meetingPoint'] !== false) {
                            self.setMeetingPointInfo(marker['meetingPoint']);
                            meetingPointDescription.classList.remove('hide');
                        } else {

                            meetingPointDescription.classList.add('hide');
                        }

                    }
                })(this.markers[i], i));
            }

        },

        setMeetingPointInfo: function(meetingPoint) {
            //Get DOMelements
            var meetingPointOpeningHoursContainer = document.querySelector('.map-wrapper .meeting-point .opening-hours-container');
            var meetingPointOpeningHours = document.querySelector('.map-wrapper .meeting-point .opening-hours');
            var meetingPointTelephone = document.querySelector('.map-wrapper .meeting-point .telephone a');
            var meetingPointEmail = document.querySelector('.map-wrapper .meeting-point .email a');
            var meetingPointFacebook = document.querySelector('.map-wrapper .meeting-point .facebook');

            //Json info
            var telephone = meetingPoint['telephone'];
            var email = meetingPoint['email'];
            var openingHours = meetingPoint['openingHours'];
            var facebook = meetingPoint['facebook'];

            if (telephone) {
                meetingPointTelephone.innerText = telephone;
                meetingPointTelephone.href = "tel:" + telephone;
                meetingPointTelephone.parentNode.parentNode.classList.remove('hide');

            } else {
                meetingPointTelephone.parentNode.parentNode.classList.add('hide');

            }

            if (email) {
                meetingPointEmail.innerText = email;
                meetingPointEmail.href = "mailto:" + email;
                meetingPointEmail.parentNode.parentNode.classList.remove('hide');

            } else {
                meetingPointEmail.parentNode.parentNode.classList.add('hide');

            }

            if (facebook) {
                //meetingPointFacebook.innerText = facebook;
                meetingPointFacebook.href = facebook;
                meetingPointFacebook.parentNode.parentNode.classList.remove('hide');
            } else {
                meetingPointEmail.parentNode.parentNode.classList.add('hide');
            }


            //Remove current opening hours before adding opening hours
            while (meetingPointOpeningHours.firstChild) {
                meetingPointOpeningHours.removeChild(meetingPointOpeningHours.firstChild);
            }



            if (openingHours) {
                //meetingPointOpeningHours.innerText = telephone;

                for (var i = 0; i < openingHours.length; i++) {
                    var openingHour = openingHours[i]['apningstid'];
                    var openingHourElement = document.createElement('P');
                    openingHourElement.classList.add('opening-hour');
                    openingHourElement.innerText = openingHour;
                    meetingPointOpeningHours.appendChild(openingHourElement);
                }

                meetingPointOpeningHoursContainer.classList.remove('hide');

            } else {
                meetingPointOpeningHoursContainer.classList.add('hide');
            }

        },


        setArrangementInfo: function(eventObject, eventObjectFormatted) {


            //Image overlay cancelled
            var overlay = document.querySelector('.map-wrapper .overlay');
            var overlayText = document.querySelector('.map-wrapper .overlay-text');

            if (eventObject['eventCancelled'] == true) {
                overlay.classList.remove('hide');
                overlayText.classList.remove('hide');
            } else {

                overlay.classList.add('hide');
                overlayText.classList.add('hide');
            }


            //Get DOMelements (rows)
            var rowCalenderSingle = document.querySelector('.row.calender-single h4');
            var rowCalenderStart = document.querySelector('.row.calender-start h4');
            var rowCalenderEnd = document.querySelector('.row.calender-end h4');
            var rowLocation = document.querySelector('.row.location h4');
            var rowCategories = document.querySelector('.row.categories h4');
            var rowPrice = document.querySelector('.row.price h4');

            //Calender start date and time
            //Starttime
            var startTime = eventObjectFormatted['startTime'];
            //Startdate
            var startDate = new Date(eventObject['startDate']);
            var options = { weekday: 'long', month: 'long', day: 'numeric' };
            startDate = startDate.toLocaleString('nb-NO', options);
            startDate = startDate.charAt(0).toUpperCase() + startDate.slice(1);

            //Calender end date and time
            //Endtime
            var endTime = eventObjectFormatted['endTime'];
            //Enddate
            var endDate = new Date(eventObject['endDate']);
            options = { weekday: 'long', month: 'long', day: 'numeric' };
            endDate = endDate.toLocaleString('nb-NO', options);
            endDate = endDate.charAt(0).toUpperCase() + endDate.slice(1);

            if (startDate !== endDate) {
                //CSS classes
                rowCalenderSingle.parentNode.classList.add('hide');
                rowCalenderStart.parentNode.classList.remove('hide');
                rowCalenderEnd.parentNode.classList.remove('hide');

                //Content
                rowCalenderStart.innerText = startDate + ' ' + startTime;
                rowCalenderEnd.innerText = endDate + ' ' + endTime;
            } else {
                //CSS classes
                rowCalenderSingle.parentNode.classList.remove('hide');
                rowCalenderStart.parentNode.classList.add('hide');
                rowCalenderEnd.parentNode.classList.add('hide');
                if (startTime !== endTime) {
                    rowCalenderSingle.innerText = startDate + ' ' + startTime + '-' + endTime;
                } else {
                    rowCalenderSingle.innerText = startDate + ' ' + startTime;
                }
            }

        },


        initialiseClosePostWindowListener: function() {
            this.postWindowContainerButton.addEventListener('click', function() {
                self.postWindowContainer.classList.add('closed');
            });
        },


        isSinglePostPage: function() {
            var marker = this.getObjectInsideArray(this.markers, 'postID', this.singlePostPagePostID);
            if (marker) {
                marker.icon = this.options.icons.activeMarker;
                this.map.setCenter(marker.position);
                this.map.setZoom(20);
            }

        },

        isSubCategoryPage: function() {
            var category = this.getObjectInsideArray(this.categories, 'term_id', this.subCategoryPageCategoryID);
            if (category) {
                this.categoryMain = category['name'];
                this.changeCategoriesMain(category['name'], category);
                var selectText = document.querySelector('.custom-dropdown-menu.main-buttons .select-box .select-text');
                selectText.innerText = category['name'];
                //self.setMapCoordinates(category['latitude'], category['longitude'], 14);
            }

        },



        //Helpers, returns either true or false
        googleMapsIsInDOM: function() {
            if (document.getElementById('ams-map')) {
                return true;
            } else {
                return false;
            }
        },
        requestResponseHasPosts: function() {
            if (this.request.response['postObjects']) {
                return true;
            } else {
                return false;
            }
        },

        requestResponseHasCategories: function() {
            if (this.request.response['categoryObjects']) {
                return true;
            } else {
                return false;
            }
        },

        setPosts: function() {
            this.posts = this.request.response['postObjects'];
        },

        setCategories: function() {
            this.categories = this.request.response['categoryObjects'];
        },


        //Get object from array by using passing key and value too look for
        getObjectInsideArray: function(array, key, value) {
            for (var j = 0; j < array.length; j++) {
                let object = array[j];
                if (object[key] == value) {
                    return object;
                }
            }
            return false;

        },

        isInArray: function(array, value) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].equals(value)) {
                    return true;
                }
            }
            return false;
        },


        //Part of construction
        //Returns ID for current post, if currently on single-post page
        //Else, undefined
        setSinglePostPagePostID: function() {
            var body = document.querySelector('body');
            var classList = body.classList;
            for (var i = 0; i < classList.length; i++) {
                if (classList[i].indexOf('postid') !== -1) {
                    let postID = classList[i].split('-')[1];
                    this.singlePostPagePostID = postID;
                }
            }

        },


        setSubCategoryPageID: function() {
            var body = document.querySelector('body');
            if (body.querySelector('.sub-category')) {
                var classList = body.querySelector('.sub-category').classList;
                for (var i = 0; i < classList.length; i++) {
                    if (classList[i].indexOf('categoryid') !== -1) {
                        let categoryID = classList[i].split('-')[1];
                        this.subCategoryPageCategoryID = categoryID;
                    }
                }
            }

        },


        //Creates the category buttons, puts every category that has argument parentToMainCategories in its own div (.parent-buttons)
        //Creates the category buttons, puts every category that has argument parentToMainCategories in its own div (.parent-buttons)
        setCategoryButtons: function(parentToMainCategories) {

            //Parent buttons container
            let categoryMainButtonsContainer = document.querySelector('.map-buttons .main-buttons select');

            //Category buttons logic. Create buttons and add evenlistener, pass the slug
            let categoryButtonsContainer = document.querySelector('.map-buttons .buttons select');

            //Create category button for "alle" for child categories
            let buttonAlleChild = document.createElement('option');
            buttonAlleChild.innerText = "Alle kategorier";
            buttonAlleChild.classList.add('alle', 'button');
            /*buttonAlleChild.addEventListener("click", function() {
                //console.log('click');
                self.changeCategoriesChild('Alle');
            });*/

            categoryButtonsContainer.appendChild(buttonAlleChild);

            //Create category button for "alle" for main categories
            let buttonAlleMain = document.createElement('option');
            buttonAlleMain.innerText = "Alle bydeler";
            buttonAlleMain.classList.add('alle', 'button');
            categoryMainButtonsContainer.appendChild(buttonAlleMain);

            //Make an array of keys from the category names. Iterate the keys and use
            //the key to index the category
            var categoriesOnMapKeys = Object.keys(this.categoriesOnMap);

            //Create category button for current categories on map
            for (let i = 0; i < categoriesOnMapKeys.length; i++) {

                //Get category by key (name)
                let category = this.categoriesOnMap[categoriesOnMapKeys[i]];

                //Create button
                let button = document.createElement('option');
                button.classList.add(category['slug'], 'button');
                button.innerText = category['name'];

                //Get categoryparent for current iteration
                var categoryParentID = category['parent'];
                var categoryParent = this.getObjectInsideArray(this.categories, 'term_id', categoryParentID);

                //If main category, add to its own main buttons div container
                if (categoryParent['slug'] == parentToMainCategories) {
                    categoryMainButtonsContainer.appendChild(button);
                }
                //Else, add to buttons container
                else {
                    categoryButtonsContainer.appendChild(button);
                }
            }
        },

        changeCategories: function(parentToMainCategories, categoryName){

            var categoryObject = this.getObjectInsideArray(this.categories, 'name', categoryName);
            var parent = categoryObject['parentName'];

            if ( (parent == parentToMainCategories) || (categoryName == 'Alle bydeler') ) {
                this.changeCategoriesMain(categoryName, categoryObject);
            }

            else {
                this.changeCategoriesChild(categoryName);
            }

        },

        changeCategoriesMain: function(categoryName, categoryObject) {

            this.categoryMain = categoryName;
            var categoryMarkers = [];
            //If categoryName is alle, set every marker to visible
            if (categoryName == "Alle bydeler") {

                categoryMarkers = this.markers;
                for (var i = 0; i < this.markers.length; i++) {
                    this.markers[i].setVisible(true);
                }
                //Recalculate and repaint the clusters
                this.updateClusters(categoryMarkers);
                self.setMapCoordinates(self.options.map.mainLatitude, self.options.map.mainLongitude, self.options.map.mainZoom);

            } else {
                for (var i = 0; i < this.markers.length; i++) {
                    //Get markercategory, if current marker has categoryName
                    var markerHasCategory = self.getObjectInsideArray(this.markers[i].categories, 'name', categoryName);
                    if (markerHasCategory) {
                        //Set marker visible and add to categoryMarkers (used to repaint the clusters)
                        this.markers[i].setVisible(true);
                        categoryMarkers.push(this.markers[i]);
                    } else {
                        this.markers[i].setVisible(false);
                    }
                }
                this.updateClusters(categoryMarkers);
                if (categoryObject['latitude'] && categoryObject['longitude']) {
                    self.setMapCoordinates(categoryObject['latitude'], categoryObject['longitude'], 14);
                }
            }


            var childSelectText = document.querySelector('.map-buttons .buttons .select-box .select-text');
            childSelectText.innerText = "Alle kategorier";


            //Update
            this.updateMapButtons(categoryMarkers);

        },


        changeCategoriesChild: function(categoryName) {
            var categoryMarkers = [];

            //Show every marker if both 'alle'-buttons are active
            if (this.categoryMain == 'Alle bydeler' && categoryName == 'Alle kategorier') {
                categoryMarkers = this.markers;
                for (var i = 0; i < this.markers.length; i++) {
                    this.markers[i].setVisible(true);
                }
            }

            //If not alle bydeler, but alle kategorier
            else if ((!(this.categoryMain == 'Alle bydeler')) && (categoryName == 'Alle kategorier')) {
                for (var i = 0; i < this.markers.length; i++) {
                    var markerHasMainCategory = self.getObjectInsideArray(this.markers[i].categories, 'name', this.categoryMain);
                    if (markerHasMainCategory) {
                        this.markers[i].setVisible(true);
                        categoryMarkers.push(this.markers[i]);
                    } else {
                        this.markers[i].setVisible(false);
                    }
                }
            }

            //If alle bydeler, but not alle kategorier
            else if (((this.categoryMain == 'Alle bydeler')) && (!(categoryName == 'Alle kategorier'))) {
                for (var i = 0; i < this.markers.length; i++) {
                    //Get markercategory, if current marker has categoryName
                    var markerHasCategory = self.getObjectInsideArray(this.markers[i].categories, 'name', categoryName);

                    if (markerHasCategory) {
                        //Set marker visible and add to categoryMarkers (used to repaint the clusters)
                        this.markers[i].setVisible(true);
                        categoryMarkers.push(this.markers[i]);
                    } else {
                        this.markers[i].setVisible(false);
                    }
                }
            }

            //If neither of 'alle'-buttons is active
            else {

                for (var i = 0; i < this.markers.length; i++) {


                    //Get markercategory, if current marker has categoryName
                    var markerHasCategory = self.getObjectInsideArray(this.markers[i].categories, 'name', categoryName);
                    var markerHasMainCategory = self.getObjectInsideArray(this.markers[i].categories, 'name', this.categoryMain);

                    if (markerHasCategory && markerHasMainCategory) {

                        //Set marker visible and add to categoryMarkers (used to repaint the clusters)
                        this.markers[i].setVisible(true);
                        categoryMarkers.push(this.markers[i]);
                    } else {
                        this.markers[i].setVisible(false);
                    }
                }

            }


            //Recalculate and repaint the clusters
            this.updateClusters(categoryMarkers)



        },

        updateMapButtons: function(categoryMarkers) {
            let categoryName = this.categoryMain;
            var categoriesOnMap = {};
            //Create object with all categories currently in use on map, after markers have been updated
            for (var i = 0; i < categoryMarkers.length; i++) {
                var marker = categoryMarkers[i];
                for (var j = 0; j < marker.categories.length; j++) {
                    if (!categoriesOnMap[marker.categories[j]['slug']]) {
                        categoriesOnMap[marker.categories[j]['slug']] = marker.categories[j];
                    }
                }
            }

            let buttons = document.querySelectorAll('.map-buttons .buttons .button');

            //Use categories currently on map to set which categorybuttons should be visible
            for (var i = 0; i < buttons.length; i++) {
                let button = buttons[i];

                //Skip 'alle'-buttons
                if (button.classList.contains('alle')) {
                    continue;
                }

                let optionListItems = document.querySelectorAll('.map-buttons .buttons .option-list > div');

                let key = button.classList[0];
                if (!categoriesOnMap[key]) {
                    optionListItems[i].classList.add('hide');
                } else {
                    optionListItems[i].classList.remove('hide');
                }
            }


        },

        updateClusters: function(categoryMarkers) {
            this.markerCluster.clearMarkers();
            this.markerCluster.addMarkers(categoryMarkers);
            this.markerCluster.repaint();
        },

        setMapCoordinates: function(latitude, longitude, zoom) {
            this.map.setCenter({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
            this.map.setZoom(zoom);
        },


    };
    //Construct PostID variable
    self.setSinglePostPagePostID();
    self.setSubCategoryPageID();

    return self;
}
