export default function LazyLoad () {

    var self = {

        array: [],
        offset: "",

        forEachElement: function(array, callback) {
            for (var i = 0; i < array.length; i++) {
                let element = array[i];
                callback(element);
            }
        },

        initialise: function(offset, array) {
            this.array = array;
            this.offset = offset;
            self.lazyLoadViewport();
        },

        lazyLoadElement: function (element) {
            var url = element.dataset.src;
            if (element.tagName == "IMG") {
                element.src = url;
                element.addEventListener('load', function() {
                    element.classList.add('loaded');
                });

            }
            else {
                let image = document.createElement('img');
                image.src = url;
                image.addEventListener('load', function() {
                    element.style.backgroundImage = "url(" + url + ")";
                    element.classList.add('loaded');
                    image = null;
                });
            }

        },

        lazyLoadOnClick: function (clickElement, loadElements) {
            var loadElements = loadElements;
            var clickElement = clickElement;
            function clickElementFunction () {
                self.forEachElement(loadElements, function(element) {
                    self.lazyLoadElement(element);
                });
                clickElement.removeEventListener('click', clickElementFunction);
            }


            clickElement.addEventListener('click', clickElementFunction);

        },


        lazyLoadViewport: function() {

            //Run once on page load, with bigger offset (200% each direciton)
            self.forEachElement(self.array, function(element) {
                if (( (self.percentageInViewport(element) + 100) > 0) && (self.percentageInViewport(element) <= 200)) {
                    self.lazyLoadElement(element);
                }
            })


            function scrollListener () {
                if (self.array.length == 0) {
                    window.removeEventListener('scroll', scrollListener);
                }
                window.requestAnimationFrame(function() {
                    self.forEachElement(self.array, function(element) {
                        if (( (self.percentageInViewport(element) + self.offset) > 0) && (self.percentageInViewport(element) <= (100 + self.offset))) {
                            self.lazyLoadElement(element);
                            //Remove from array when done loading
                            var index = self.array.indexOf(element);
                            self.array.splice(index, 1);
                        }
                    })
                });
            }

            window.addEventListener('scroll', scrollListener);
        },

        percentageInViewport: function(element) {
            return 100 - (element.getBoundingClientRect().top / (window.innerHeight || document.documentElement.clientHeight) * 100);
        }
    }
    return self;

}
