export default function LoaderAnimation () {

    var self = {

        pageLoader: document.querySelector('.map-loader'),
        pageLoaderIcon: document.querySelector('.map-loader .loader-icon'),

        endLoading: function () {

            this.pageLoader.style.opacity = "0";
            setTimeout(function(){
                self.pageLoader.style.cssText = "display:none;"
                self.pageLoaderIcon.classList.remove("spinning");
            }, 1000);
        }
    }

    return self;
}
